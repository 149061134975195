/**
 * Generated by orval v6.9.6 🍺
 * Do not edit manually.
 * Recovr Private API
 * This is the private API for Recovr
 * OpenAPI spec version: 1.0
 */
// eslint-disable-next-line @typescript-eslint/no-redeclare
export var StepTypeParameter = {
    preventive_reminder: 'preventive_reminder',
    first_reminder: 'first_reminder',
    intermediate_reminder: 'intermediate_reminder',
    last_reminder: 'last_reminder',
    task_generation: 'task_generation',
};
